import { all, put, takeEvery, fork } from 'redux-saga/effects';
import actions from './actions';
import { get, post, PUT } from '@iso/lib/helpers/resApiRequestor';
// import { get, post, PUT } from '@iso/lib/helpers/resApiRequestor';

export function* uploadExcelFile() {
  yield takeEvery('UPLOAD_EXCEL_FILE', function* (payload) {
    try {
      yield put({
        type: actions.UPLOADING_EXCEL_FILE,
      });
      const apiResult = yield batchUploadMerchants(payload.payload);
      const result = apiResult.data;
      // const state = yield select();
      // const mqttResponse = state.Tools.excelData;
      if (result.success) {
        const apiResult = yield fetchThirdPartyData();
        const finalData = apiResult.data;
        yield put({
          type: actions.UPLOADED_EXCEL_FILE,
          data: result.data,
          finalResult: finalData.data,
          messageSuccess: 'File uploaded successfully.'
        });
      } else {
        yield put({
          type: actions.UPLOAD_EXCEL_FILE_FAILED,
          messageFailed: apiResult.data.message ? apiResult.data.message : 'File upload failed.'
        });
      }
    } catch (error) {
      yield put({
        type: actions.UPLOAD_EXCEL_FILE_FAILED,
        messageFailed: 'File upload failed.'
      });
    }
  });
}

export function* fetchThirdPartyResult() {
  yield takeEvery('FETCH_THIRD_PARTY_DATA', function* () {
    try {
      const apiResult = yield fetchThirdPartyData();
      const result = apiResult.data;
      yield put({
        type: actions.FETCHING_THIRD_PARTY_DATA,
      });
      if (result.success === true) {
        yield put({
          type: actions.FETCHED_THIRD_PARTY_DATA,
          payload: result.data,
        });
      }
    } catch (error) {
      yield put({
        type: actions.FETCH_THIRD_PARTY_DATA_FAILED,
        payload: error,
      });
    }
  });
}

export function* fetchThirdPartyDataSearchResult() {
  yield takeEvery('SEARCH_THIRD_PARTY_DATA', function* (payload) {
    try {
      yield put({
        type: actions.SEARCHING_THIRD_PARTY_DATA,
      });
      const apiResult = yield searchResult(payload.payload);
      const result = apiResult.data;
      console.log('BATCH_SUMMARY', result);
      if (result) {
        yield put({
          type: actions.SEARCHED_THIRD_PARTY_DATA,
          payload: result.stream.rows,
          countValidator: result.arrayData,
        });
        yield put({
          type: actions.FETCHED_BATCH_SUMMARY,
          payload: result.batchSummaryData,
        });
        yield put({
          type: actions.NEW_FETCHED_STATUS_COUNT,
          statusCountData: result.statusCountData,
        });
      }
    } catch (error) {
      yield put({
        type: actions.SEARCH_THIRD_PARTY_DATA_FAILED,
        payload: error,
      });
    }
  });
}

export function* fetchThirdPartyByUserResult() {
  yield takeEvery('FETCH_THIRD_PARTY_DATA_BY_USER', function* (payload) {
    try {
      const apiResult = yield fetchThirdPartyDataByUser(payload.payload);
      const result = apiResult.data;
      // const newResult = JSON.parse(result.data.data);
      yield put({
        type: actions.FETCHING_THIRD_PARTY_DATA_BY_USER,
      });
      if (result.success) {
        yield put({
          type: actions.FETCHED_THIRD_PARTY_DATA_BY_USER,
          payload: result.data,
        });
      }
    } catch (error) {
      yield put({
        type: actions.FETCH_THIRD_PARTY_DATA_BY_USER_FAILED,
        payload: error,
      });
    }
  });
}

export function* fetchBatchSummary() {
  yield takeEvery('FETCH_BATCH_SUMMARY', function* () {
    try {
      const apiResult = yield fetchBatchSummaryData();
      const result = apiResult.data;
      yield put({
        type: actions.FETCHING_BATCH_SUMMARY,
      });
      if (result.success === true) {
        yield put({
          type: actions.FETCHED_BATCH_SUMMARY,
          payload: result.data.result,
        });
      }
    } catch (error) {
      yield put({
        type: actions.FETCH_BATCH_SUMMARY_FAILED,
        payload: error,
      });
    }
  });
}

export function* fetchBatchByName() {
  yield takeEvery('FETCH_BATCH_BY_NAME', function* (payload) {
    try {
      yield put({
        type: actions.FETCHING_BATCH_BY_NAME,
      });
      const apiResult = yield fetchBatchSummaryByName(payload.payload);
      const result = apiResult.data;
      // const newResult = JSON.parse(result.data.data);
      if (result.success === true) {
        yield put({
          type: actions.FETCHED_BATCH_BY_NAME,
          payload: result.data.thirdPartyData,
          countValidator: result.data.countValidator,
        });
      }
    } catch (error) {
      yield put({
        type: actions.FETCH_BATCH_BY_NAME_FAILED,
        payload: error,
      });
    }
  });
}

export function* fetchFilterStatus() {
  yield takeEvery('FETCH_FILTER_STATUS', function* () {
    try {
      const apiResult = yield fetchFilterStatusData();
      const result = apiResult.data;
      yield put({
        type: actions.FETCHING_FILTER_STATUS,
      });
      if (result.success === true) {
        yield put({
          type: actions.FETCHED_FILTER_STATUS,
          payload: result.data,
        });
      }
    } catch (error) {
      yield put({
        type: actions.FETCH_FILTER_STATUS_FAILED,
        payload: error,
      });
    }
  });
}

export function* removeBatchEntry() {
  yield takeEvery('REMOVE_BATCH_UPLOAD_ENTRY', function* (payload) {
    try {
      yield put({
        type: actions.REMOVING_BATCH_UPLOAD_ENTRY,
      });
      const apiResult = yield removeBatchUploadEntry(payload.payload);
      const result = apiResult.data;
      if (result.success === true) {
        yield put({
          type: actions.REMOVED_BATCH_UPLOAD_ENTRY,
          payload: result.data,
          removeMsgSuccess: 'Removed successfully!',
        });
      }
    } catch (error) {
      yield put({
        type: actions.REMOVE_BATCH_UPLOAD_ENTRY_FAILED,
        payload: error,
        removeMsgFailed: 'Removed Failed!',
      });
    }
  });
}

export function* fetchDataByStatus() {
  yield takeEvery('FETCH_DATA_BY_STATUS', function* (payload) {
    try {
      yield put({
        type: actions.FETCHING_DATA_BY_STATUS,
      });
      const apiResult = yield getDataByStatus(payload.payload);
      const result = apiResult.data;
      if (result) {
        yield put({
          type: actions.FETCHED_DATA_BY_STATUS,
          payload: result.data.filterData,
          countValidator: result.data.countValidator,
          filterStatusCount: result.data.filterStatusCount,
        });
        yield put({
          type: actions.FETCHED_BATCH_SUMMARY,
          payload: result.data.batchSummaryData,
        });
      }
    } catch (error) {
      yield put({
        type: actions.FETCH_DATA_BY_STATUS_FAILED,
        payload: error,
      });
    }
  });
}

export function* fetchCountStatus() {
  yield takeEvery('FETCH_STATUS_COUNT', function* (payload) {
    try {
      const apiResult = yield getStatusCount(payload.payload);
      const result = apiResult.data;
      yield put({
        type: actions.FETCHING_STATUS_COUNT,
      });
      if (result) {
        yield put({
          type: actions.FETCHED_STATUS_COUNT,
          payload: result.data,
        });
      }
    } catch (error) {
      yield put({
        type: actions.FETCH_STATUS_COUNT_FAILED,
        payload: error,
      });
    }
  });
}

// Batch Upload Merchant
function batchUploadMerchants(data) {
  return post(`thirdpartydata/batch_upload_merchants_xlsx_format/`, data);
  // return post(`thirdpartydata/batch_upload_merchants/`, data);
}

function fetchThirdPartyData() {
  return get(`thirdpartydata/get_all_batch_upload/`);
}

function searchResult(data) {
  return post(`thirdpartydata/search_batch_upload_data/`, data);
}

function fetchThirdPartyDataByUser(user) {
  return get(`thirdpartydata/fetch_data_by_username/${user}`);
}

function fetchBatchSummaryData() {
  return get(`thirdpartydata/get_batch_summary`);
}

function fetchBatchSummaryByName(name) {
  return get(`thirdpartydata/get_by_batch_name/${name}`);
}

function fetchFilterStatusData() {
  return get(`thirdpartydata/get_filter_status`);
}

function removeBatchUploadEntry(id) {
  return PUT(`thirdpartydata/update_remove/${id}`);
}

function getDataByStatus(data) {
  return post(`thirdpartydata/search_filter_by_status/`, data);
}

function getStatusCount(id) {
  return get(`thirdpartydata/get_status_count/${id}`);
}

export default function* rootSaga() {
  yield all([
    fork(uploadExcelFile),
    fork(fetchThirdPartyResult),
    fork(fetchThirdPartyDataSearchResult),
    fork(fetchThirdPartyByUserResult),
    fork(fetchBatchSummary),
    fork(fetchBatchByName),
    fork(fetchFilterStatus),
    fork(removeBatchEntry),
    fork(fetchDataByStatus),
    fork(fetchCountStatus),
  ]);
}
