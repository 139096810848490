const actions = {

  // BATCH UPLOAD MERCHANT
  UPLOAD_EXCEL_FILE: 'UPLOAD_EXCEL_FILE',
  UPLOADING_EXCEL_FILE: 'UPLOADING_EXCEL_FILE',
  UPLOADED_EXCEL_FILE: 'UPLOADED_EXCEL_FILE',
  UPLOAD_EXCEL_FILE_FAILED: 'UPLOAD_EXCEL_FILE_FAILED',
  RESET_UPLOAD_MESSAGE: 'RESET_UPLOAD_MESSAGE',

  // GET BATCH UPLOAD MERCHANT DATA
  FETCH_THIRD_PARTY_DATA: 'FETCH_THIRD_PARTY_DATA',
  FETCHING_THIRD_PARTY_DATA: 'FETCHING_THIRD_PARTY_DATA',
  FETCHED_THIRD_PARTY_DATA: 'FETCHED_THIRD_PARTY_DATA',
  FETCH_THIRD_PARTY_DATA_FAILED: 'FETCH_THIRD_PARTY_DATA_FAILED',

  // GET SEARCH DATA RESULT
  SEARCH_THIRD_PARTY_DATA: 'SEARCH_THIRD_PARTY_DATA',
  SEARCHING_THIRD_PARTY_DATA: 'SEARCHING_THIRD_PARTY_DATA',
  SEARCHED_THIRD_PARTY_DATA: 'SEARCHED_THIRD_PARTY_DATA',
  SEARCH_THIRD_PARTY_DATA_FAILED: 'SEARCH_THIRD_PARTY_DATA_FAILED',
  RESET_SEARCH_THIRD_PARTY_DATA: 'RESET_SEARCH_THIRD_PARTY_DATA',

  // GET BATCH UPLOAD DATA BY USERNAME
  FETCH_THIRD_PARTY_DATA_BY_USER: 'FETCH_THIRD_PARTY_DATA_BY_USER',
  FETCHING_THIRD_PARTY_DATA_BY_USER: 'FETCHING_THIRD_PARTY_DATA_BY_USER',
  FETCHED_THIRD_PARTY_DATA_BY_USER: 'FETCHED_THIRD_PARTY_DATA_BY_USER',
  FETCH_THIRD_PARTY_DATA_BY_USER_FAILED: 'FETCH_THIRD_PARTY_DATA_BY_USER_FAILED',
  RESET_FETCH_THIRD_PARTY_DATA_BY_USER: 'RESET_FETCH_THIRD_PARTY_DATA_BY_USER',

  // GET BATCH SUMMARY
  FETCH_BATCH_SUMMARY: 'FETCH_BATCH_SUMMARY',
  FETCHING_BATCH_SUMMARY: 'FETCHING_BATCH_SUMMARY',
  FETCHED_BATCH_SUMMARY: 'FETCHED_BATCH_SUMMARY',
  FETCH_BATCH_SUMMARY_FAILED: 'FETCH_BATCH_SUMMARY_FAILED',

  // GET BATCH SUMMARY BY NAME
  FETCH_BATCH_BY_NAME: 'FETCH_BATCH_BY_NAME',
  FETCHING_BATCH_BY_NAME: 'FETCHING_BATCH_BY_NAME',
  FETCHED_BATCH_BY_NAME: 'FETCHED_BATCH_BY_NAME',
  FETCH_BATCH_BY_NAME_FAILED: 'FETCH_BATCH_BY_NAME_FAILED',

  // GET FILTER STATUS
  FETCH_FILTER_STATUS: 'FETCH_FILTER_STATUS',
  FETCHING_FILTER_STATUS: 'FETCHING_FILTER_STATUS',
  FETCHED_FILTER_STATUS: 'FETCHED_FILTER_STATUS',
  FETCH_FILTER_STATUS_FAILED: 'FETCH_FILTER_STATUS_FAILED',

  // REMOVE BATCH UPLOAD ENTRY
  REMOVE_BATCH_UPLOAD_ENTRY: 'REMOVE_BATCH_UPLOAD_ENTRY',
  REMOVING_BATCH_UPLOAD_ENTRY: 'REMOVING_BATCH_UPLOAD_ENTRY',
  REMOVED_BATCH_UPLOAD_ENTRY: 'REMOVED_BATCH_UPLOAD_ENTRY',
  REMOVE_BATCH_UPLOAD_ENTRY_FAILED: 'REMOVE_BATCH_UPLOAD_ENTRY_FAILED',
  RESET_REMOVE_MESSAGE: 'RESET_REMOVE_MESSAGE',

  // FILTER BY STATUS
  FETCH_DATA_BY_STATUS: 'FETCH_DATA_BY_STATUS',
  FETCHING_DATA_BY_STATUS: 'FETCHING_DATA_BY_STATUS',
  FETCHED_DATA_BY_STATUS: 'FETCHED_DATA_BY_STATUS',
  FETCH_DATA_BY_STATUS_FAILED: 'FETCH_DATA_BY_STATUS_FAILED',

  // FETCH STATUS COUNT
  FETCH_STATUS_COUNT: 'FETCH_STATUS_COUNT',
  FETCHING_STATUS_COUNT: 'FETCHING_STATUS_COUNT',
  FETCHED_STATUS_COUNT: 'FETCHED_STATUS_COUNT',
  NEW_FETCHED_STATUS_COUNT: 'NEW_FETCHED_STATUS_COUNT',
  FETCH_STATUS_COUNT_FAILED: 'FETCH_STATUS_COUNT_FAILED',
  
  batchUploadMerchant: (payload) => ({
    type: actions.UPLOAD_EXCEL_FILE,
    payload,
  }),

  fetchAllThirdPartyData: () => ({
    type: actions.FETCH_THIRD_PARTY_DATA,
  }),

  resetUploadMessage: () => ({
    type: actions.RESET_UPLOAD_MESSAGE,
  }),
  
  searchThirdPartyData: (payload) => ({
    type: actions.SEARCH_THIRD_PARTY_DATA,
    payload,
  }),

  resetSearchThirdPartyData: () => ({
    type: actions.RESET_SEARCH_THIRD_PARTY_DATA,
  }),

  fetchThirdPartyDataByUser: (payload) => ({
    type: actions.FETCH_THIRD_PARTY_DATA_BY_USER,
    payload,
  }),  
  
  resetThirdPartyDataByUser: () => ({
    type: actions.RESET_FETCH_THIRD_PARTY_DATA_BY_USER,
  }),

  fetchBatchSummary: () => ({
    type: actions.FETCH_BATCH_SUMMARY,
  }),

  fetchBatchByName: (payload) => ({
    type: actions.FETCH_BATCH_BY_NAME,
    payload,
  }),

  fetchFilterStatus: () => ({
    type: actions.FETCH_FILTER_STATUS,
  }),

  removeBatchUploadEntry: (payload) => ({
    type: actions.REMOVE_BATCH_UPLOAD_ENTRY,
    payload,
  }),

  filterByStatus: (payload) => ({
    type: actions.FETCH_DATA_BY_STATUS,
    payload,
  }),

  fetchStatusCount: (payload) => ({
    type: actions.FETCH_STATUS_COUNT,
    payload,
  }),

  resetRemoveMessage: () => ({
    type: actions.RESET_REMOVE_MESSAGE,
  }),
};
export default actions;
